import React, { Component } from 'react';
import { redes_sociales } from '../assets/data/redes-sociales';

class SocialBar extends Component {
    state = {}
    openUrl = (url) => {
        window.open(url, '_blank');
    }
    render() {
        return (
            <div className='container-fluid p-0 text-center' style={{
                borderRadius: '10px 10px 0px 0px',
                maxWidth: '1080px'
            }}>
                <div className="row p-0 m-0 shadowContainer" style={{
                    width: '100%',
                    maxWidth: '1080px',
                    height: '60px',
                    position: 'fixed',
                    overflow: 'hidden',
                    bottom: 0,
                    borderRadius: '10px 10px 0px 0px',
                    backgroundColor: '#f7f7f7',
                }}>
                    {redes_sociales.map((rs) => (
                        <a key={rs.title} href={rs.url} target="_blank" className='col m-0 p-0 align-self-center'>
                            <div style={{ background: rs.backgroundColor }}><i className={"bi-" + rs.icon} style={{ fontSize: '18px', color: rs.iconColor, lineHeight: '60px' }}></i></div>
                        </a>
                    ))}
                </div>
            </div>
        );
    }
}

export default SocialBar;