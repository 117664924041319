import React, { Component } from 'react';
import logo from '../assets/images/logo.png'

class AboutUs extends Component {
    state = {}
    render() {
        return (
            <div className='m-5 text-center'>
                <img alt='logoabuot' className='mb-3' src={logo} style={{
                    maxWidth: '300px'
                }}/>
                <h3 className='fw-bold text-center'>¿Quiénes Somos?</h3>
                <p className='text-start'>Somos expertos en el mercado automotriz, dedicados a conectar a nuestros clientes con las mejores soluciones en vehículos y servicios. Con años de experiencia en el sector, ofrecemos una amplia gama de autos de las marcas más reconocidas, tanto nuevos como seminuevos, garantizando calidad y satisfacción en cada transacción.
                    Nuestro equipo está comprometido con brindar asesoría personalizada, asegurándonos de que cada cliente encuentre el vehículo que se ajuste a sus necesidades, estilo y presupuesto. Además, contamos con un servicio postventa confiable, enfocado en mantener tu auto en óptimas condiciones.
                    Más que una autoagencia, somos tu aliado en la carretera. Nuestra misión es ofrecer transparencia, confianza y seguridad en cada compra, creando relaciones a largo plazo con nuestros clientes y ofreciendo un servicio que nos distingue en el mercado.
                    ¡Te invitamos a conocernos y descubrir por qué somos la mejor opción para tu próximo vehículo!</p>
            </div>
        );
    }
}

export default AboutUs;