import React, { Component } from 'react';
import { politica, terminos } from '../assets/data/politica-terminos';

class PoliticaPrivacidad extends Component {
    state = {}
    render() {
        return (
            <div className='m-0 mt-3'>
                <div>
                    <a data-bs-toggle="modal" data-bs-target="#politicas" style={{ textDecoration: 'underline' }}>
                        <i>Políticas de Privacidad</i>
                    </a>
                </div>
                <div>
                    <a data-bs-toggle="modal" data-bs-target="#terminos" style={{ textDecoration: 'underline' }}>
                        <i>Términos y Condiciones</i>
                    </a>
                </div>

                <div className="modal fade" id="politicas" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="politicasLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="politicasLabel">Politicas de Privacidad</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-start" style={{ whiteSpace: 'pre-wrap' }}>
                                {politica}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="terminos" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="terminosLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="terminosLabel">Términos y Condiciones</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-start" style={{ whiteSpace: 'pre-wrap' }}>
                                {terminos}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
    }
}

export default PoliticaPrivacidad;