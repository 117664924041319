import { Component } from "react";
import banner from "../assets/images/banner.jpg"

class Header extends Component {
    state = {}
    render() {
        return (
            <div
                className="shadowContainer position-relative"
                style={{
                    backgroundImage: `url(${banner})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top",
                    backgroundSize: "cover",
                    maxWidth: "1080px",
                    borderRadius: '10px',
                    maxHeight: '500px',
                    height: '35vh',
                    overflow: 'hidden',
                }}
            >
                <a href="#registerform" style={{
                    border: "1px solid black"
                }} className="btn btn-lg btn-warning position-absolute top-50 start-50 translate-middle mt-5 fw-bold">¡Obtén tu entrada gratuita!</a>
            </div>
        );
    }
}

export default Header;