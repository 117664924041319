import React, { Component } from 'react';
import logo from '../assets/images/logo.png'
import PoliticaPrivacidad from './PoliticaPrivacidad';

class Footer extends Component {
    state = {  } 
    render() { 
        return (
            <div className='text-center p-5' style={{width: '100%'}}>
                <img alt='logo' src={logo} style={{
                    height: '50px'
                }}/>
                <PoliticaPrivacidad></PoliticaPrivacidad>
                {/* <p><a className='devaez' target='blank' href='https://devaez.com'>DEVAEZ | 2024</a></p> */}
            </div>
        );
    }
}
 
export default Footer;