import React, { Component } from 'react';

class Location extends Component {
    state = {}
    render() {
        return (
            <div className='shadowContainer' 
            style={{
                backgroundColor: 'rgba(223, 84, 62, 1)'
            }} >
                <iframe title='maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3595.790750735476!2d-100.2879791!3d25.6782303!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662942a8d42b18f%3A0x1eee54a9dfbc9332!2sCintermex!5e0!3m2!1ses-419!2smx!4v1726074528116!5m2!1ses-419!2smx" width="100%" height="450" style={{border: 0}} allowFullScreen="0" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        );
    }
}

export default Location;