export const marcas = [
    { value: 'alfaromeo', label: 'Alfa Romeo' },
    { value: 'chevrolet', label: 'Chevrolet' },
    { value: 'chirey', label: 'Chirey' },
    { value: 'dodge', label: 'Dodge' },
    { value: 'fiat', label: 'Fiat' },
    { value: 'ford', label: 'Ford' },
    { value: 'geely', label: 'Geely' },
    { value: 'gmw', label: 'GMW' },
    { value: 'jaecoo', label: 'Jaecoo' },
    { value: 'jeep', label: 'Jeep' },
    { value: 'jetour', label: 'Jetour' },
    { value: 'kia', label: 'KIA' },
    { value: 'mercedesbenz', label: 'Mercedes-Benz' },
    { value: 'mg', label: 'MG' },
    { value: 'nissan', label: 'Nissan' },
    { value: 'omoda', label: 'OMODA' },
    { value: 'peugeot', label: 'Peugeot' },
    { value: 'ram', label: 'RAM' },
    { value: 'seat', label: 'SEAT' },
    { value: 'volkswagen', label: 'Volkswagen' },
];

export const modelos = {
    alfaromeo: [
        { value: 'tonale2024', label: 'Tonale 2024' },
        { value: 'giulia2024', label: 'Giulia 2024' },
        { value: 'stelvio2024', label: 'Stelvio 2024' }
    ],
    chevrolet: [
        { value: 'aveo2024', label: 'Aveo 2024' },
        { value: 'aveohatchback2024', label: 'Aveo Hatchback 2024' },
        { value: 'groove2024', label: 'Groove 2024' },
        { value: 'cavalier2024', label: 'Cavalier 2024' },
        { value: 'tracker2024', label: 'Tracker 2024' },
        { value: 's102024', label: 'S10 2024' },
        { value: 'captiva2024', label: 'Captiva 2024' },
        { value: 'trax2024', label: 'Trax 2024' },
        { value: 'montana2024', label: 'Montana 2024' },
        { value: 'silverado2024', label: 'Silverado 2024' },
        { value: 'cheyenne2024', label: 'Cheyenne 2024' },
        { value: 'blaze2024', label: 'Blaze 2024' },
        { value: 'tahoe2024', label: 'Tahoe 2024' },
        { value: 'onix2024', label: 'Onix 2024' },
        { value: 'bolteuv2023', label: 'BOLT EUV 2023' },
        { value: 'suburban2024', label: 'Suburban 2024' },
        { value: 'colorado2024', label: 'Colorado 2024' },
        { value: 'tornadovan2024', label: 'Tornado Van 2024' },
        { value: 'expresspassenger2024', label: 'Express Passenger 2024' },
        { value: 'expresscargo2024', label: 'Express Cargo 2024' },
        { value: 'equinoxev2024', label: 'Equinox EV 2024' }
    ],
    chirey: [
        { value: 'tiggo22024', label: 'Tiggo 2 2024' },
        { value: 'tiggo42024', label: 'Tiggo 4 2024' },
        { value: 'tiggo7pro2024', label: 'Tiggo 7 Pro 2024' },
        { value: 'tiggo8pro2024', label: 'Tiggo 8 Pro 2024' },
        { value: 'tiggo8promax2024', label: 'Tiggo 8 Pro Max 2024' },
        { value: 'tiggo9proe2024', label: 'Tiggo 9 Pro E+ 2024' },
        { value: 'arrizo82024', label: 'Arrizo 8 2024' },
        { value: 'tiggo7epro2024', label: 'Tiggo 7 E+ Pro 2024' },
        { value: 'tiggo7promax2024', label: 'Tiggo 7 Pro Max 2024' }
    ],
    dodge: [
        { value: 'attitude2024', label: 'Attitude 2024' },
        { value: 'journey2024', label: 'Journey 2024' },
        { value: 'challenger2024', label: 'Challenger 2024' },
        { value: 'durango2024', label: 'Durango 2024' },
        { value: 'charger2024', label: 'Charger 2024' }
    ],
    fiat: [
        { value: 'fastback2024', label: 'Fastback 2024' },
        { value: 'mobi2024', label: 'Mobi 2024' },
        { value: 'argo2024', label: 'Argo 2024' },
        { value: 'pulse2024', label: 'Pulse 2024' }
    ],
    ford: [
        { value: 'territory2024', label: 'Territory 2024' },
        { value: 'mustang2024', label: 'Mustang 2024' },
        { value: 'expedition2024', label: 'Expedition 2024' },
        { value: 'lobo2024', label: 'Lobo 2024' },
        { value: 'escape2024', label: 'Escape 2024' },
        { value: 'transitcourier2023', label: 'Transit Courier 2023' },
        { value: 'ranger2024', label: 'Ranger 2024' },
        { value: 'f250superduty2024', label: 'F-250 Super Duty 2024' },
        { value: 'broncotaptor2024', label: 'Bronco Raptor 2024' },
        { value: 'bonoraptor2024', label: 'Bono Raptor 2024' },
        { value: 'superdutychasis2023', label: 'Super Duty Chasis 2023' },
        { value: 'transvan2024', label: 'Trans Van 2024' },
        { value: 'mustangmache2022', label: 'Mustang Mach-E 2022' },
        { value: 'explorer2024', label: 'Explorer 2024' },
        { value: 'maverick2023', label: 'Maverick 2023' },
        { value: 'broncosport2024', label: 'Bronco Sport 2024' },
        { value: 'edge2024', label: 'Edge 2024' },
        { value: 'rangertaptor2024', label: 'Ranger Raptor 2024' },
        { value: 'bronco2024', label: 'Bronco 2024' },
        { value: 'f1502023', label: 'F-150 2023' },
        { value: 'etransit2024', label: 'E-Transit 2024' }
    ],
    geely: [
        { value: 'coolray2024', label: 'COOLRAY 2024' },
        { value: 'geometryc2024', label: 'GEOMETRY C 2024' },
        { value: 'okavango2024', label: 'OKAVANGO 2024' },
        { value: 'starray2024', label: 'STARRAY 2024' },
        { value: 'gx3pro2024', label: 'Gx3 Pro 2024' }
    ],
    gmw: [
        { value: 'orae3100electrico2024', label: 'ORA E3 100% ELECTRICO 2024' },
        { value: 'havalh6hibrida2024', label: 'HAVAL H6 HIBRIDA 2024' },
        { value: 'havaljolion2024', label: 'HAVAL JOLION 2024' },
        { value: 'jolionhev2024', label: 'JOLION HEV 2024' },
        { value: 'tank300hev2025', label: 'TANK 300 HEV 2025' },
        { value: 'poermt2025', label: 'POER MT 2025' },
        { value: 'poerat2025', label: 'POER AT 2025' }
    ],
    jaecoo: [
        { value: 'jaecoo72024', label: 'Jaecoo 7 2024' }
    ],
    jeep: [
        { value: 'renegade2024', label: 'Renegade 2024' },
        { value: 'compass2024', label: 'Compass 2024' },
        { value: 'grandcherokeel2024', label: 'Grand Cherokee L 2024' },
        { value: 'jt2023', label: 'JT 2023' },
        { value: 'wranglerrubicon2024', label: 'Wrangler Rubicon 2024' },
        { value: 'grandwagoneer2023', label: 'Grand Wagoneer 2023' }
    ],
    jetour: [
        { value: 'x702024', label: 'X70 2024' },
        { value: 'x70plus2024', label: 'X70 Plus 2024' },
        { value: 'dashing2024', label: 'Dashing 2024' },
        { value: 't22024', label: 'T2 2024' }
    ],
    kia: [
        { value: 'k4sedan2025', label: 'K4 Sedan 2025' },
        { value: 'k3hatchback2024', label: 'K3 Hatchback 2024' },
        { value: 'k3sedan2024', label: 'K3 Sedan 2024' },
        { value: 'fortesedan2024', label: 'Forte Sedan 2024' },
        { value: 'sonet2024', label: 'Sonet 2024' },
        { value: 'soul2024', label: 'Soul 2024' },
        { value: 'niro2024', label: 'Niro 2024' },
        { value: 'seltos2024', label: 'Seltos 2024' },
        { value: 'sportage2024', label: 'Sportage 2024' },
        { value: 'sorento2024', label: 'Sorento 2024' },
        { value: 'telluride2024', label: 'Telluride 2024' }
    ],
    mercedesbenz: [
        { value: 'clasea2023', label: 'Clase A 2023' },
        { value: 'cla2024', label: 'CLA 2024' },
        { value: 'glb2024', label: 'GLB 2024' },
        { value: 'clases2024', label: 'Clase S 2024' },
        { value: 'claseg2024', label: 'Clase G 2024' },
        { value: 'cls2024', label: 'CLS 2024' },
        { value: 'gle2024', label: 'GLE 2024' },
        { value: 'clasee2024', label: 'Clase E 2024' },
        { value: 'glc2024', label: 'GLC 2024' },
        { value: 'gls2024', label: 'GLS 2024' },
        { value: 'clasec2024', label: 'Clase C 2024' },
        { value: 'gla2024', label: 'GLA 2024' },
        { value: 'amggt534matic', label: 'AMG GT 53 4MATIC' },
        { value: 'eqs2023', label: 'EQS 2023' },
        { value: 'eqa2023', label: 'EQA 2023' },
        { value: 'eqb2023', label: 'EQB 2023' },
        { value: 'eqe2023', label: 'EQE 2023' }
    ],
    mg: [
        { value: 'px52024', label: 'PX5 2024' },
        { value: 'mgone2024', label: 'MG One 2024' },
        { value: 'hs2024', label: 'HS 2024' },
        { value: 'ehs2024', label: 'EHS 2024' },
        { value: 'zsev2024', label: 'ZS EV 2024' },
        { value: 'gt2024', label: 'GT 2024' },
        { value: 'mg52024', label: 'MG5 2024' },
        { value: 'rx82024', label: 'RX8 2024' },
        { value: 'zs2024', label: 'ZS 2024' },
        { value: 'mg42024', label: 'MG4 2024' },
        { value: 'mg32025', label: 'MG3 2025' },
        { value: '3hybrid2025', label: '3 Hybrid 2025' }
    ],
    nissan: [
        { value: 'versa2024', label: 'Versa 2024' },
        { value: 'sentra2024', label: 'Sentra 2024' },
        { value: 'march2024', label: 'March 2024' },
        { value: 'vdrive2024', label: 'V-Drive 2024' },
        { value: 'kicks2024', label: 'Kicks 2024' },
        { value: 'np3002024', label: 'NP 300 2024' },
        { value: 'frontier2024', label: 'Frontier 2024' },
        { value: 'kicksepower2024', label: 'Kicks E-Power 2024' },
        { value: 'urvan2024', label: 'Urvan 2024' },
        { value: 'altima2024', label: 'Altima 2024' },
        { value: 'xtrail2024', label: 'X-Trail 2024' },
        { value: 'xtrailepower2024', label: 'X-Trail E-Power 2024' },
        { value: 'pathfinder2024', label: 'Pathfinder 2024' }
    ],
    omoda: [
        { value: 'o5gt2024', label: 'O5 GT 2024' },
        { value: 'o52024', label: 'O5 2024' },
        { value: 'c52024', label: 'C5 2024' }
    ],
    peugeot: [
        { value: 'partner2024', label: 'Partner 2024' },
        { value: 'rifter2024', label: 'Rifter 2024' },
        { value: '20082024', label: '2008 2024' },
        { value: '30082024', label: '3008 2024' },
        { value: 'expertfurgon2024', label: 'Expert Furgón 2024' },
        { value: 'manager2024', label: 'Manager 2024' },
        { value: 'epartner2024', label: 'E-Partner 2024' },
        { value: 'partnerraid2024', label: 'Partner Raid 2024' }
    ],
    ram: [
        { value: '2500hd2024', label: '2500 HD 2024' },
        { value: '7002024', label: '700 2024' },
        { value: '1500hemisport2023', label: '1500 Hemi Sport 2023' },
        { value: '1500ccmildhybridbighornv64x42024', label: '1500 CC Mild-Hybrid Bighorn V6 4x4 2024' },
        { value: '1500ccmildhybridlonghorn4x42024', label: '1500 CC Mild-Hybrid Longhorn 4x4 2024' },
        { value: '1500ccmildhybridlaramiesport4x42024', label: '1500 CC Mild-Hybrid Laramie Sport 4x4 2024' },
        { value: 'promaster2023', label: 'Promaster 2023' },
        { value: '40002024', label: '4000 2024' },
        { value: '1500ccmildhybridlimited4x42024', label: '1500 CC Mild-Hybrid Limited 4x4 2024' },
        { value: '1500slt2023', label: '1500 SLT 2023' },
        { value: 'ram1500trx2024', label: 'Ram 1500 TRX 2024' },
        { value: '1500ccmildhybridtradesmanv64x42024', label: '1500 CC Mild-Hybrid Tradesman V6 4x4 2024' }
    ],
    seat: [
        { value: 'mo1252023', label: 'Mó 125 2023' },
        { value: 'ibiza2024', label: 'Ibiza 2024' },
        { value: 'arona2024', label: 'Arona 2024' },
        { value: 'leon2023', label: 'León 2023' },
        { value: 'ateca2024', label: 'Ateca 2024' },
        { value: 'tarraco2023', label: 'Tarraco 2023' }
    ],
    volkswagen: [
        { value: 'teramont2024', label: 'Teramont 2024' },
        { value: 'crosssport2024', label: 'Cross Sport 2024' },
        { value: 'tiguan2024', label: 'Tiguan 2024' },
        { value: 'taos2024', label: 'Taos 2024' },
        { value: 'taigun2024', label: 'Taigun 2024' },
        { value: 'nivus2024', label: 'Nivus 2024' },
        { value: 'polo2024', label: 'Polo 2024' },
        { value: 'virtus2024', label: 'Virtus 2024' },
        { value: 'gli40aniversario2024', label: 'GLI 40 Aniversario 2024' },
        { value: 'jetta2024', label: 'Jetta 2024' },
        { value: 'saveiro2024', label: 'Saveiro 2024' },
        { value: 'amarok20tdi2023', label: 'Amarok 2.0 TDI 2023' },
        { value: 'caddypasajeros2024', label: 'Caddy Pasajeros 2024' },
        { value: 'transporterpasajeros2023', label: 'Transporter Pasajeros 2023' },
        { value: 'transportercargovan2024', label: 'Transporter Cargo Van 2024' },
        { value: 'craftercargovan2024', label: 'Crafter Cargo Van 2024' },
        { value: 'crafterchasiscabina2023', label: 'Crafter Chasis Cabina 2023' },
        { value: 'transporterchasiscabina2023', label: 'Transporter Chasis Cabina 2023' }
    ]
};

export const fechas = [
    { value: '19Oct', label: '19 de Octubre del 2024' },
    { value: '20Oct', label: '20 de Octubre del 2024' },
]
