import React, { Component } from 'react';
import { marcas, modelos, fechas } from '../assets/data/marcas-modelos'

class RegisterForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            marcaSelectValue: '',
            modeloSelectValue: '',
            modeloSelectOptions: [],
            isModeloEnable: false,
            responseMsg: 'Por el momento el registro no está disponible, mantente atento a nuestras redes sociales.',
            responseType: 'warning',
            showAlert: true

        };
    }

    marcasOptions = marcas;
    modeloOptions = modelos;

    saveRegister = (tg) => {
        const formData = new FormData(tg.target);

        const formJSON = {};
        formData.forEach((value, key) => {
            formJSON[key] = value;
        });

        fetch("/register.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formJSON)
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                if (data.success) {
                    this.setState({
                        showAlert: true,
                        responseType: 'success',
                        responseMsg: 'Registro Exitoso, en breve recibirás un correo electrónico con la información de tu registro.'
                    });
                } else {
                    this.setState({
                        showAlert: true,
                        responseType: 'danger',
                        responseMsg: 'Error en el registro. Inténtalo de nuevo.'
                    });
                }
            })
            .catch((e) => {
                this.setState({
                    showAlert: true,
                    responseType: 'danger',
                    responseMsg: 'Error en la comunicación con el servidor. Inténtalo más tarde.'
                });
            });
    }


    handleSubmit = (tg) => {
        tg.preventDefault();
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute('6LdcDT4qAAAAAPp0H8VE9290xoEnUTCTl0yP3rpI', { action: 'submit' })
                .then((token) => {
                    fetch("/verify.php", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            token: token
                        })
                    })
                        .then((res) => {
                            return res.json();
                        })
                        .then((data) => {
                            if (data.success && data.score >= 0.5) {
                                this.saveRegister(tg);
                            } else {
                                this.setState({
                                    showAlert: true,
                                    responseType: 'danger',
                                    responseMsg: 'Error en la verificación. Inténtalo de nuevo.'
                                });
                            }
                        })
                        .catch((e) => {
                            this.setState({
                                showAlert: true,
                                responseType: 'danger',
                                responseMsg: 'Error en la comunicación con el servidor. Inténtalo más tarde.'
                            });
                        })
                })
                .catch((e) => {
                    this.setState({
                        showAlert: true,
                        responseType: 'danger',
                        responseMsg: 'Error al ejecutar reCAPTCHA. Inténtalo más tarde.'
                    });
                });
        });

    }

    handleMarcaSelectChange = (e) => {
        const selected = e.target.value;

        this.setState({
            marcaSelectValue: selected,
            modeloSelectOptions: this.modeloOptions[selected] || [],
            isModeloEnable: !!selected
        })
    }

    handleModeloSelectChange = (e) => {
        const selected = e.target.value;

        this.setState({
            modeloSelectValue: selected,
        })
    }

    render() {
        return (
            <div id='registerform'>
                <div className='p-5'>
                    <h1 className='fw-bold text-center mb-0'>Registro de Asistencia</h1>
                    <p className='text-center mb-3'>Ingresa tu datos para continuar:</p>
                    <form method='POST' onSubmit={this.handleSubmit} style={{ display: 'none' }}>
                        <div className='mb-3'>
                            <input type="text" required className='form-control' placeholder='Nombre' name="name" />
                        </div>
                        <div className='mb-3'>
                            <input type="email" required className='form-control' placeholder='Correo Electrónico' name="email" />
                        </div>
                        <div className='mb-3'>
                            <input type="text" required className='form-control' placeholder='Teléfono' name="telefono" />
                        </div>
                        <div className='mb-3'>
                            <select name='marca' required value={this.state.marcaSelectValue} onChange={this.handleMarcaSelectChange} className='form-select'>
                                <option value="" disabled>Seleccione una Marca</option>
                                {
                                    this.marcasOptions.map((option) => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='mb-3'>
                            <select name='modelo' required className='form-select' value={this.state.modeloSelectValue} onChange={this.handleModeloSelectChange} disabled={!this.state.isModeloEnable}>
                                <option value="" disabled >Seleccione un modelo</option>
                                {this.state.modeloSelectOptions.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className='mb-3'>
                            <select name='evento' required className='form-select'>
                                <option value="" disabled >Seleccione una fecha</option>
                                {fechas.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className='text-center mb-4'>
                            <button className='btn btn-dark' type="submit">Registrar mi Asistencia</button>
                        </div>
                    </form>
                    <div id='form_response' className={"alert alert-" + this.state.responseType + " text-center"} style={{ display: this.state.showAlert ? 'block' : 'none' }} role="alert">
                        {this.state.responseMsg}
                    </div>
                </div>
            </div>
        );
    }
}

export default RegisterForm;