export const politica = `Para agendar tu cita, solicitamos información personal, como tu nombre, correo electrónico y número de teléfono. Estos datos son necesarios para procesar tu solicitud y enviarte la confirmación de la cita.\n
La información proporcionada será utilizada exclusivamente para gestionar tu cita y mantenerte informado sobre cualquier cambio o cancelación. No compartimos tus datos personales con terceros, salvo que sea necesario para cumplir con obligaciones legales.\n
Implementamos medidas de seguridad para proteger tu información personal. Sin embargo, no podemos garantizar la seguridad absoluta en las transmisiones de datos a través de internet. Al utilizar nuestro servicio, reconoces y aceptas los riesgos inherentes a la transmisión de información.\n
Puedes solicitar en cualquier momento la rectificación, actualización o eliminación de tus datos personales. Para hacerlo, por favor contacta a nuestro equipo de atención al cliente mediante el formulario de contacto.\n
Nos reservamos el derecho de modificar estas políticas en cualquier momento. Te notificaremos cualquier cambio relevante a través del correo electrónico proporcionado.
`;

export const terminos = `Al utilizar nuestra plataforma para agendar una cita, aceptas los presentes Términos y Condiciones, así como nuestras Políticas de Privacidad. Si no estás de acuerdo con alguno de los términos, te recomendamos no utilizar nuestro servicio.
 
Al llenar el formulario de contacto y agendar tu cita, recibirás un correo de confirmación con un código QR único y un número de referencia. Este código QR deberá presentarse el día de tu cita para confirmar tu asistencia. Es responsabilidad del cliente asegurarse de que la información proporcionada en el formulario sea precisa y actualizada.
 
La cita queda formalmente agendada una vez que recibas el correo de confirmación. Nos reservamos el derecho de modificar o cancelar las citas, en cuyo caso te notificaremos con anticipación a través de los datos de contacto proporcionados.
 
El usuario es responsable de verificar que la cita fue agendada correctamente y de acudir al lugar en la fecha y hora establecidas. El incumplimiento de la asistencia sin previo aviso podría resultar en la imposibilidad de reprogramar la cita.
 
Si necesitas modificar o cancelar tu cita, deberás hacerlo con al menos 24 horas de antelación utilizando el enlace provisto en el correo de confirmación o contactando directamente a nuestro equipo de atención.
 
En caso de no asistencia a la cita sin previo aviso, nos reservamos el derecho de restringir futuras reservas.
 
El código QR que te enviamos es único y personal. No debe compartirse ni duplicarse. En caso de mal uso o uso indebido del código QR, nos reservamos el derecho de anular la cita y tomar las medidas correspondientes.`;