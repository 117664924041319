import React, { useEffect, useRef } from 'react';

const VideoHeader = () => {
    const playerRef = useRef(null);

    useEffect(() => {
      // Verifica si la API de YouTube ya está cargada
      if (!window.YT) {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      } else {
        loadPlayer();
      }
  
      // Función para inicializar el reproductor cuando la API de YouTube esté lista
      window.onYouTubeIframeAPIReady = () => {
        loadPlayer();
      };
  
      function loadPlayer() {
        playerRef.current = new window.YT.Player('player', {
          height: '390',
          width: '100%',
          videoId: 'zK6x_BqWGAc',
          playerVars: {
            'playsinline': 1,
            'loop': 1,
            'playlist': 'zK6x_BqWGAc',
            'controls': 0,
            'mute':  1,
          },
          events: {
            'onReady': onPlayerReady,
            // 'onStateChange': onPlayerStateChange
          }
        });
      }
  
      function onPlayerReady(event) {
        event.target.playVideo();
      }
  
    //   let done = false;
    //   function onPlayerStateChange(event) {
    //     console.log(event.data)
    //     if (event.data === window.YT.PlayerState.PLAYING && !done) {
    //       setTimeout(() => {            
    //         playerRef.current.playVideo();
    //       }, 6000);
    //       done = true;
    //     }
    //   }
  
      return () => {
        if (playerRef.current) {
          playerRef.current.destroy();
        }
      };
    }, []);
  
    return (<div id="player"></div>);
};

export default VideoHeader;